import axios from "axios";

let apiUrl = 'https://data.krachtengeduld.be';

// LOCAL STORAGE METHODS
export function saveToLocalStorage(key, data) {
  let storageItem = {
    'time': (new Date()).toTimeString(),
    'data': data,
  }
  localStorage.setItem(key, JSON.stringify(storageItem))
}

export function getFromLocalStorage(key) {
  let value = JSON.parse(localStorage.getItem(key));
  return value ? value.data : [];
}


// DATABASE STORAGE METHODS
export function saveToDatabase(data = {}) {
  axios.post(apiUrl + '/villaKG/saveEvents?client=VillaKG', data)
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {
      console.log(error);
    });
}
