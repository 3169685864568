import React from "react";
import './Hero.scss';

function Hero(props) {
    return (
        <>
            <div id={'hero'} className={props.size ?? 'medium'}>
                {props.children}
            </div>
            <div className={'heroPointer'}><span /></div>
        </>
    );
}

export default Hero;
