import React, { Component } from "react";
import { saveToDatabase, saveToLocalStorage } from "./utils";
import './TimeTable.scss';

const sizeHeightMapping = { 'S': 60, 'M': 80, 'L': 100, 'XL': 120 };

export class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: this.parseHours(props.startHour ?? 0, props.endHour ?? 23),
      headers: props.headers ?? ['Event-name'],
      lanes: props.events ?? [[]],
      startHour: props.startHour ?? 0,
      endHour: props.endHour ?? 23,
      oneHourHeight: sizeHeightMapping[props.size] ?? 80,
      selectedEvents: props.selected ?? [],
      selectedTag: props.selected_tag,
    };
  }

  parseHours(start, end) {
    let initialHours = [];
    for (let i = start; i < end; i++) {
      initialHours.push(i);
    }
    return initialHours;
  }

  renderHours() {
    return <div id={'hourColumn'}>{
      this.state.hours.map((hour, i) =>
        <span key={i} style={{ height: this.state.oneHourHeight + 'px' }}>{hour}u</span>
      )}
    </div>;
  }

  renderHeaders() {
    return <>{this.state.headers.map((header, i) =>
      <p className={'eventColumnTitle'} key={i}>{header}</p>
    )}</>;
  }

  renderLanes() {
    return <>{this.state.lanes.map((lane, i) =>
      <div className={'eventColumn'} key={i}>
        {lane.map((event) => {
          return this.renderEvents(event)
        })}
      </div>
    )}</>;
  }

  renderEvents(event) {
    let paddingVert = 2;
    let minutesOffset = (event.startHour * 60 + event.startMinute) - (this.state.startHour * 60);

    let style = {
      top: this.state.oneHourHeight * (minutesOffset / 60) + paddingVert,
      height: (event.duration / 60) * this.state.oneHourHeight - paddingVert
    };

    let time = event.startHour + ':' + event.startMinute.toString().padStart(2, '0');
    let classNames = this.state.selectedEvents.includes(event.id) ? 'event selected' : 'event';

    if (event.duration <= 30) {
      classNames += ' smallPadding';
    }

    return <div
      key={event.id}
      className={classNames}
      style={style}
      onClick={() => this.onEventClick(event.id)}
    >
      <div className={'eventTop'}>
        <span className={'eventTitle'}>{event.title}</span>
        <span className={'eventTime'}>{time}</span>
      </div>
      <span className={'eventDescription'}>{event.description}</span>
    </div>
  }

  renderInfoBlock() {
    let amountOfEvents = this.state.selectedEvents.length;

    if (amountOfEvents === 0) {
      return <div className={'infoDiv'}>
        <p>Laat ons weten of je aanwezig zal zijn door bovenstaande events te selecteren!</p><br />
      </div>
    }

    let info = amountOfEvents > 1
      ? 'Je zal aanwezig zijn op deze ' + amountOfEvents + ' events:'
      : 'Je zal aanwezig zijn op dit event:';

    let eventNames = [];
    this.state.lanes.forEach(lane => {
      lane.forEach(event => {
        if (this.state.selectedEvents.includes(event.id)) {
          eventNames.push(event.title)
        }
      })
    })

    return <div className={'infoDiv'}>
      <p>{info}
        {eventNames.length > 0 &&
          <><br /><b>{eventNames.join(' , ')}</b></>
        }
      </p>
    </div>;
  }

  onEventClick(eventId) {
    let events = this.state.selectedEvents;
    if (events.includes(eventId)) {
      // remove event id
      events = events.filter((eId) => eId !== eventId);
    } else {
      // add event id
      events.push(eventId);
    }

    events = events.sort();

    this.setState({ selectedEvents: events });
    saveToLocalStorage(this.state.selectedTag, events);
    saveToDatabase({ 'eventIds': events, 'key': this.state.selectedTag.substr(-5) });
  }

  render() {
    return (<>
      <div id={'timetable'}>

        <div id={'timetableHeader'}> {this.renderHeaders()}</div>

        <div id={'timetableContent'}>
          {this.renderHours()}
          {this.renderLanes()}
        </div>

      </div>
      {this.renderInfoBlock()}
    </>);
  }
}
