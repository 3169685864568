import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as IconsBrands from "@fortawesome/free-brands-svg-icons";
import './Footer.scss';

function Footer() {
    return (
        <div id={'footer'} className={'spacing-top-l'}>
            <h1 className={'marker'}>Blijf op de hoogte</h1>

            <p className={'spacing-top-m'}>
                Komende weken staat Villa KG in de kijker op onze online kanalen.
                <br />
                Je houdt dus best <b>onze socials</b> in de gaten voor leuke info en last-minute wijzigingen!
            </p>

            <div className={'socials spacing-top-s'}>
                <a href={'https://www.facebook.com/events/4964711713565641'}><FontAwesomeIcon icon={IconsBrands.faFacebookSquare} /></a>
                <a href={'https://www.instagram.com/krachtengeduld'}><FontAwesomeIcon icon={IconsBrands.faInstagramSquare} /></a>
            </div>
        </div>
    );
}

export default Footer;
