import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import './Navigation.scss';

// Currently unused due to lack of navigation needs

function Navigation() {
    return (
        <div id={'mainNav'}>
            <NavLink className={'navItem'} to={'/'}><FontAwesomeIcon icon={Icons.faHome} /></NavLink>
            <NavLink className={'navItem'} to={'/events'}><FontAwesomeIcon icon={Icons.faColumns} /></NavLink>
        </div>
    );
}

export default Navigation;
