import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SkewedContainer = (props) => {
    const calculateSkew = (propValue, position) => {
        var skewYDeg, skewPosition;
        switch (propValue) {
            case 'left':
                position === 'top' ? skewYDeg = -props.skew : skewYDeg = props.skew;
                skewPosition = 'left';
                break;
            case 'right':
                position === 'top' ? skewYDeg = props.skew : skewYDeg = -props.skew;
                skewPosition = 'right';
                break;
            default:
                skewYDeg = '';
                skewPosition = '';
                break;
        }
        return `${position}:0; transform-origin: ${skewPosition} ${position}; transform: skewY(${skewYDeg}Deg);`;
    }

    const containerMargin = () => {
        let marginTop = (props.top !== '') ? props.margin : 0;
        let marginBottom = (props.bottom !== '') ? props.margin : 0;

        return `margin: ${marginTop} 0 ${marginBottom};`;
    }

    const commonStyling = `
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: inherit;
        z-index: -1;
    `;

    const Container = styled.div`
        ${containerMargin()}
        width: 100%;
        position: relative;
        background-color: ${props.bgColor} !important;
        z-index: 2;

        &:before{
            ${commonStyling}
            ${calculateSkew(props.top, "top")}
        }
        &:after {
            ${commonStyling}
            ${calculateSkew(props.bottom, "bottom")}
        }
    `;

    const Content = styled.div`
        width: 100%;
        overflow: auto;
    `;

    return (
        <Container style={props.style}>
            <Content className={props.className}>
                {props.children}
            </Content>
        </Container>
    );
}

SkewedContainer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    top: PropTypes.string,
    bottom: PropTypes.string,
    bgColor: PropTypes.string,
    margin: PropTypes.string,
    skew: PropTypes.number
}

SkewedContainer.defaultProps = {
    className: '',
    style: {},
    top: '',
    bottom: '',
    bgColor: 'grey',
    margin: '70px',
    skew: 3
}

export default SkewedContainer;
