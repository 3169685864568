import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import './Introduction.scss';

function Introduction() {
    return (
        <div id={'introduction'}>
            <h1 className={'marker'}>Een Festival?</h1>

            <p>
                Inderdaad!&nbsp;
                <br className={'mobile-only'} />
                Je mag je verwachten aan een terrein met <b>meerdere podia</b>,&nbsp;
                <br className={'not-on-mobile'} />
                een speelstraat, een heuse BBQ en lekkere <b>eet- en drankstandjes. </b>
                <br /><br />
                Uiteraard zijn er ook <b>optredens, workshops en open lessen</b>, door en voor alle leden!.
            </p>

            <div className={'spacing-top-l blockRow'}>
                <div className={'block'}><FontAwesomeIcon icon={Icons.faStar} /></div>
                <div className={'block'}><FontAwesomeIcon icon={Icons.faUtensils} /></div>
                <div className={'block'}><FontAwesomeIcon icon={Icons.faDoorOpen} /></div>
            </div>
        </div>
    );
}

export default Introduction;
