import React from "react";
import { Footer, Hero, Introduction, SectionBBQ, SectionLineUp, SkewedContainer } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-regular-svg-icons";
import Logo from "../media/logo-wit.png";
import './Home.scss';

function Home() {
    return (
        <div id={'home'} className={'mainContainer'}>
            <Hero size={'medium'}>
                <img src={Logo} id={'logo'} className={'img-fluid'} alt='logo' />
                <h4>Een zomers en sportief festival<br className={'mobile-only'} /> op <b>24 en 25 juni</b></h4>
            </Hero>

            <Introduction />

            <br className={'spacing-top-xxl'} />

            <div className={'days'}>
                <SkewedContainer top={'left'} bottom={'right'} bgColor={'#3a3163'}
                                 margin={'40px'} className={'dayContainer'}>
                    <h1>VRIJDAG <b>&middot;</b> 24 JUNI</h1>
                    <ul>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Afterwork drinks</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Open lessen</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Tapas en aperitiefhapjes</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Zomerbar met cocktails</li>
                    </ul>
                </SkewedContainer>

                <SkewedContainer top={'left'} bottom={'right'} bgColor={'#3a3163'}
                                 margin={'40px'} className={'dayContainer'}>
                    <h1>ZATERDAG <b>&middot;</b> 25 JUNI</h1>
                    <ul>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Optredens en workshops</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />BBQ en lekkere eetstandjes</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Speelstraat voor jong en oud</li>
                        <li><FontAwesomeIcon icon={Icons.faCheckCircle} />Zomerbar met cocktails</li>
                    </ul>
                </SkewedContainer>
            </div>

            <SectionBBQ />

            <SectionLineUp />

            <Footer />
        </div>
    );
}

export default Home;
