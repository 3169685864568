import React, { useState } from "react";
import { getFromLocalStorage } from "./utils";
import { TimeTable } from "./TimeTable";
import './Section.scss';

function SectionLineUp() {
    const [selectedDay, setSelectedDay] = useState('24-06');

    let timeTableSettings = {
        '24-06': {
            startHour: 16,
            endHour: 24,
            size: 'S',
            headers: [
                'Drinks',
                'Food',
                'Open Lessen',
            ],
            selected: getFromLocalStorage('selected_24-06'),
            selected_tag: 'selected_24-06',
            events: [
                [
                    {
                        id: 11,
                        title: 'Cocktail & Zomerbar',
                        startHour: 16,
                        startMinute: 30,
                        duration: 360,
                        description: 'Gezellig iets drinken op de afterwork activiteiten.'
                    },
                ],
                [
                    {
                        id: 21,
                        title: 'Apéro & Tapas',
                        startHour: 18,
                        startMinute: 0,
                        duration: 180,
                        description: 'Het barteam voorziet verschillende aperitiefhapjes en tapas.'
                    },
                ],
                [
                    {
                        id: 31,
                        title: 'BBB Low',
                        startHour: 19,
                        startMinute: 30,
                        duration: 60,
                        description: 'Open les - doe gerust mee!'
                    },
                    {
                        id: 32,
                        title: 'Turners',
                        startHour: 20,
                        startMinute: 30,
                        duration: 150,
                        description: 'Open les - doe gerust mee!'
                    },
                ],
            ],
        },
        '25-06': {
            startHour: 9,
            endHour: 22,
            size: 'S',
            headers: [
                'High & Dry',
                'The Arena',
                'Shake Stage',
                'Food Court',
            ],
            selected: getFromLocalStorage('selected_25-06'),
            selected_tag: 'selected_25-06',
            events: [
                [
                    {
                        id: 101,
                        title: 'ProRecrea',
                        startHour: 10,
                        startMinute: 0,
                        duration: 120,
                        description: 'Interne Competitie - Kom zeker een kijkje nemen!'
                    },
                    {
                        id: 102,
                        title: 'Jongens 4-5-6',
                        startHour: 14,
                        startMinute: 30,
                        duration: 60,
                        description: 'Open les - Doe mee!'
                    },
                    {
                        id: 103,
                        title: 'Gympies',
                        startHour: 16,
                        startMinute: 30,
                        duration: 60,
                        description: 'Open les - Doe mee!'
                    },
                ],
                [
                    {
                        id: 201,
                        title: 'Kleuter Gym',
                        startHour: 9,
                        startMinute: 30,
                        duration: 120,
                        description: 'Open les - Doe mee!'
                    },
                    {
                        id: 202,
                        title: 'Meisjes 1-2-3',
                        startHour: 14,
                        startMinute: 0,
                        duration: 60,
                        description: 'Open les - Doe mee!'
                    },
                    {
                        id: 203,
                        title: 'Meisjes 4-5-6',
                        startHour: 15,
                        startMinute: 30,
                        duration: 60,
                        description: 'Open les - Doe mee!'
                    },
                ],
                [
                    {
                        id: 301,
                        title: 'DansFiesta',
                        startHour: 14,
                        startMinute: 30,
                        duration: 150,
                        description: 'Show van verschillende dansgroepen.'
                    },
                    {
                        id: 302,
                        title: 'Salsa Initiatie',
                        startHour: 14,
                        startMinute: 0,
                        duration: 30,
                        description: ''
                    },
                    {
                        id: 303,
                        title: 'Bluesband',
                        startHour: 19,
                        startMinute: 30,
                        duration: 120,
                        description: 'Het akoestische blues duo "Mississippi Mud" voorziet een sfeervol optreden.'
                    },
                ],
                [
                    {
                        id: 403,
                        title: 'Zomerbar',
                        startHour: 9,
                        startMinute: 30,
                        duration: 120,
                        description: 'Een groot aanbod aan verfrissende dranken staan op jou te wachten.'
                    },
                    {
                        id: 401,
                        title: 'BBQ Shift 1',
                        startHour: 11,
                        startMinute: 30,
                        duration: 150,
                        description: 'Geniet van een gezellige BBQ. Niet ingeschreven? Passeer dan eens bij onze eetstandjes!'
                    },
                    {
                        id: 404,
                        title: 'Cocktail/Zomerbar',
                        startHour: 14,
                        startMinute: 0,
                        duration: 210,
                        description: 'Onze zomerse cocktails en verfrissende dranken zorgen voor een glimlach op jouw gezicht.'
                    },
                    {
                        id: 402,
                        title: 'BBQ Shift 2',
                        startHour: 17,
                        startMinute: 30,
                        duration: 150,
                        description: 'Geniet van een gezellige BBQ. Niet ingeschreven? Passeer dan eens bij onze eetstandjes!'
                    },
                    {
                        id: 405,
                        title: 'Zomerbar',
                        startHour: 20,
                        startMinute: 0,
                        duration: 118,
                        description: 'De avond afsluiten met een gepast drankje. Puur genieten.'
                    },
                ],
            ],
        }
    }

    return (
        <div id={'section-lineup'} className={'spacing-top-xl'}>
            <h1 className={'marker'}>Overzicht activiteiten</h1>

            <p></p>

            <div className={'daySelector spacing-top-l'}>
                <a className={selectedDay === '24-06' ? 'selected' : ''} onClick={() => setSelectedDay('24-06')}>
                    Vrijdag 24/06
                </a>
                <a className={selectedDay === '25-06' ? 'selected' : ''} onClick={() => setSelectedDay('25-06')}>
                    Zaterdag 25/06
                </a>
            </div>

            <br />

            <TimeTable {...timeTableSettings[selectedDay]} key={selectedDay} />

        </div>
    );
}

export default SectionLineUp;
