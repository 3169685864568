import React from "react";
import './Section.scss';
import { saveAs } from "file-saver";

function SectionBBQ() {
    // const saveFile = () => {
    //     saveAs('https://www.krachtengeduld.be/communicatie/Formulier-BBQ.pdf', 'KG_BBQ.pdf');
    // };

    return (
        <div id={'section-bbq'} className={'spacing-top-xl'}>
            <h1 className={'marker'}>Smullen op dé KG-BBQ</h1>

            <p className={'spacing-top-l'}>
                Geen feestgevoel zonder lekker eten! Daarom voorzien we 25 juni ook een grote barbeque!<br />
                Dit jaar is de BBQ opgedeeld in <b>2 shiften</b>, ééntje op de middag (11u30) en in de avond
                (17u30).<br />
                Kom voor (of na) de andere activiteiten samen met je vrienden en familie tafelen!<br />
            </p>

            <p className={'spacing-top-l'}>
                <b>De deadline om in te schrijven is verstreken. </b> Niet ingeschreven? Geen probleem.<br/>
                Je kan ook genieten van een hapje uit één van de verschillende eetstanden.
            </p>
        </div>
    );
}

export default SectionBBQ;
