import React from "react";
import ReactDOM from "react-dom";
import { Home } from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.scss';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
